import React, { useState, useEffect } from "react"
import { graphql, navigate } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  FeatureImage,
  FeatureImageHero,
  FeatureImageHeroMobile,
  H2,
  Paragraph,
  Container,
  CTAButton,
  TwoColumnContainer,
  ThreeColumnGrid
} from "../components/styled/components"
import Fade from "react-reveal/Fade"
import { useMediaQuery } from "react-responsive"
import phone_process from "../images/phone process.png"
import phone_arrow from "../images/phone arrow.png"
import screen1 from "../images/phone screen 1.png"
import screen2 from "../images/phone screen 2.png"
import screen3 from "../images/phone screen 3.png"

const WellToLifePage = ({ data }) => {
  const [activeScreen, setActiveScreen] = useState(1);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })

  useEffect(() => {
    const timer = setTimeout(() => {
      if (activeScreen === 1) {
        setActiveScreen(2);
      } else if (activeScreen === 2) {
        setActiveScreen(3);
      } else if (activeScreen === 3) {
        setActiveScreen(1);
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [activeScreen]);

  return (
    <Layout>
      <SEO title="Well to Life" />

      {isTabletOrMobile ?
      <FeatureImageHeroMobile>
        <H2 fontSize="40px" textAlign="center">
          {data.contentfulPage.heroHeader}
        </H2>
        <CTAButton
          onClick={() => navigate("/contact/")}
        >
          GET STARTED
        </CTAButton>
        <Img fluid={data.contentfulPage.heroImage.fluid} alt={data.contentfulPage.heroImage.title} />
      </FeatureImageHeroMobile> :
      <FeatureImageHero>
        <section>
          <h2>
            {data.contentfulPage.heroHeader}
          </h2>
          <Paragraph>
            {data.contentfulPage.heroDescription.heroDescription}
          </Paragraph>
          <CTAButton
            mt={4}
            css={{ width: "300px !important" }}
            onClick={() => navigate("/contact/")}
          >
            GET STARTED
          </CTAButton>
        </section>
        <FeatureImage>
          <Img fluid={data.contentfulPage.heroImage.fluid} alt={data.contentfulPage.heroImage.title} />
        </FeatureImage>
      </FeatureImageHero>}

      <Container>
        <Fade bottom>
          <H2 fontSize="52px">Complete Turnkey Solution</H2>
          <Img fluid={data.photo2.childImageSharp.fluid} alt="Turnkey Solution"/>
        </Fade>
      </Container>

      <Container>
        <Fade bottom>
          <H2 fontSize="52px">Digitally Enabled COVID-19 Testing Experience</H2>
          <TwoColumnContainer style={{ gridTemplateColumns: "1fr 4fr", width: "90%", margin: "auto auto 100px auto" }}>
            <div>
              <img style={{ width: "100%" }} src={phone_process} alt="Steps Process" />
            </div>
            <ThreeColumnGrid style={{ gridTemplateColumns: "6fr 1fr 6fr 1fr 6fr", gridColumnGap: 0 }}>
              <div style={activeScreen === 1 ? { opacity: 1, transform: "scale(1.1)" } : { opacity: 0.5 }}>
                <img style={{ width: "100%" }} src={screen1} alt="Phone Screen 1" />
              </div>
              <div style={{ position: "relative" }}>
                <img style={{ position: "absolute", top: "50%", width: "100%" }} src={phone_arrow} alt="Arrow" />
              </div>
              <div style={activeScreen === 2 ? { opacity: 1, transform: "scale(1.1)" } : { opacity: 0.5 }}>
                <img style={{ width: "100%" }} src={screen2} alt="Phone Screen 2" />
              </div>
              <div style={{ position: "relative" }}>
                <img style={{ position: "absolute", top: "50%", width: "100%" }} src={phone_arrow} alt="Arrow" />
              </div>
              <div style={activeScreen === 3 ? { opacity: 1, transform: "scale(1.1)" } : { opacity: 0.5 }}>
                <img style={{ width: "100%" }} src={screen3} alt="Phone Screen 3" />
              </div>
            </ThreeColumnGrid>
          </TwoColumnContainer>
        </Fade>
      </Container>

      <Container>
        <Fade bottom>
          <H2 fontSize="52px">Testing Options</H2>
          <span>All modalities are FDA and EUA approved</span>
          <Img fluid={data.photo3.childImageSharp.fluid} alt="Testing Options"/>
        </Fade>
      </Container>
    </Layout>
  )
}

export default WellToLifePage

export const query = graphql`
  query WellToLifePageQuery {
    contentfulPage(pageName: {eq: "Well to Life"}) {
      heroHeader
      heroDescription {
        heroDescription
      }
      heroImage {
        title
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
    photo1: file(relativePath: { eq: "back_to_life_page_hero.png" }) {
      childImageSharp {
        fluid(maxHeight: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photo2: file(relativePath: { eq: "turnkey-solution.png" }) {
      childImageSharp {
        fluid(maxHeight: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photo3: file(relativePath: { eq: "testing-options.png" }) {
      childImageSharp {
        fluid(maxHeight: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
